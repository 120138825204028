<script>

const atf = require('@/assets/boxes/atf-box.jpg')
const confucius = require('@/assets/boxes/confucius-box.jpg')
const _ = require('lodash')

export default {
  methods: {
    boxPath: function (game) {
      switch (_.lowerCase(game)) {
        case 'atf':
          return atf
        case 'confucius':
          return confucius
        default:
          return ''
      }
    }
  }
}
</script>
