<template>
  <v-app id='app'>
    <sn-toolbar v-model="nav"></sn-toolbar>
    <sn-nav-drawer v-model="nav" app></sn-nav-drawer>
    <sn-snackbar v-model="snackbar.open">
      <div class="text-xs-center">
        {{snackbar.message}}
      </div>
    </sn-snackbar>
    <v-main>  
      <v-container grid-list-md >
        <v-layout row wrap>
          <v-flex xs6>
            <component :is='form' :invitation='invitation' @submit='putData'> </component>
          </v-flex>
          <v-flex xs6>
            <component :is='credits' > </component>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <sn-footer></sn-footer>
  </v-app>
</template>

<script>

import Toolbar from '@/components/lib/Toolbar'
import NavDrawer from '@/components/lib/NavDrawer'
import Snackbar from '@/components/lib/Snackbar'
import Footer from '@/components/lib/Footer'

import CurrentUser from '@/components/lib/mixins/CurrentUser'

import ATFForm from '@/components/invitation/atf/Form'
import ATFCredits from '@/components/invitation/atf/Credits'
import ConfuciusForm from '@/components/invitation/confucius/Form'
import ConfuciusCredits from '@/components/invitation/confucius/Credits'

const _ = require('lodash')
const axios = require('axios')

export default {
  name: 'newInvitation',
  mixins: [ CurrentUser ],
  data () {
    return {
      show: false,
      invitation: {
        title: '',
        password: '',
      },
      nav: false,
    }
  },
  components: {
    'sn-form-atf': ATFForm,
    'sn-credits-atf': ATFCredits,
    'sn-form-confucius': ConfuciusForm,
    'sn-credits-confucius': ConfuciusCredits,
    'sn-toolbar': Toolbar,
    'sn-snackbar': Snackbar,
    'sn-nav-drawer': NavDrawer,
    'sn-footer': Footer
  },
  created () {
    let self = this
    self.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  computed: {
    snackbar: {
      get: function () {
        return this.$root.snackbar
      },
      set: function (value) {
        this.$root.snackbar = value
      }
    },
    form: function () {
      return `sn-form-${this.$route.params.type}`
    },
    credits: function () {
      return `sn-credits-${this.$route.params.type}`
    },
    path: function () {
      return `${this.$route.params.type}/invitation/new`
    },
  },
  methods: {
    updateInvitation (data) {
      let self = this

      let msg = _.get(data, 'data.message', false)
      if (msg) {
        self.snackbar.message = msg
        self.snackbar.open = true
      }
      let invitation = _.get(data, 'data.invitation', false)
      if (invitation) {
        self.invitation = invitation
      }
      let cu = _.get(data, 'data.cu', false)
      if (cu) {
        self.cu = cu
        self.cuLoading = false
      }
    },
    fetchData () {
      let self = this
      axios.get(self.path)
        .then(function (response) {
          self.updateInvitation(response)
          self.loading = false
        })
        .catch(function () {
          self.loading = false
          self.snackbar.message = 'Server Error.  Try refreshing page.'
          self.snackbar.open = true
        })
    },
    putData (invitation) {
      let self = this
      self.loading = true
      axios.put(self.path, invitation)
        .then(function (response) {
          self.updateInvitation(response)
          self.loading = false
        })
        .catch(function () {
          self.loading = false
          self.snackbar.message = 'Server Error.  Try again.'
          self.snackbar.open = true
          self.$router.push({ name: 'home'})
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2, h3 {
  font-weight: normal;
}
</style>
