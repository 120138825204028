<template>
  <v-app id='app'>
    <sn-toolbar v-model='nav'></sn-toolbar>
    <sn-nav-drawer v-model='nav' app></sn-nav-drawer>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols='12' md='6' >
                  <v-card 
                    min-width='320' class='my-4'
                    >
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <div class='title font-weight-black text-center'>Welcome to SlothNinja Games</div>
                          <div class='body-1 pb-2' v-if='!cu'>
                            Registration is required in order to play. Simply login via your Google Account and register an account.
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card 
                    min-width='320'
                    class='my-4'
                    v-for="(game, index) in games"
                    :key='index'
                    :to='game.homelink' >
                    <v-card-text>
                      <v-row>
                        <v-col align='left' cols='4'>
                          <v-img
                              :src="assetPath(game.image)"
                              height='100'
                              contain
                              >
                          </v-img>
                        </v-col>
                    <v-col cols='8'>
                      <v-card-title>{{game.name}}</v-card-title>
                      <v-card-actions v-if='cu'>
                        <v-row>
                          <v-col>
                            <v-btn small color='blue' dark :to='game.playlink'>Play</v-btn>
                          </v-col>
                          <v-col>
                            <v-btn small color='blue' dark :to='game.joinlink'>Join</v-btn>
                          </v-col>
                          <v-col>
                            <v-btn small color='blue' dark :to='game.createlink'>Create</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
    <sn-footer></sn-footer>
  </v-app>
</template>

<script>
import Toolbar from '@/components/lib/Toolbar'
import NavDrawer from '@/components/lib/NavDrawer'
import Footer from '@/components/lib/Footer'
import CurrentUser from '@/components/lib/mixins/CurrentUser'
import AssetPaths from '@/components/mixins/AssetPaths'

const _ = require('lodash')
const axios = require('axios')

export default {
  name: 'home',
  mixins: [ CurrentUser, AssetPaths ],
  data: function () {
    return {
      nav: false
    }
  },
  components: {
    'sn-toolbar': Toolbar,
    'sn-nav-drawer': NavDrawer,
    'sn-footer': Footer
  },
  created () {
    let self = this
    self.fetchData()
  },
  methods: {
    myUpdate: function (data) {
      let self = this

      if (_.has(data, 'cu')) {
        self.cu = data.cu
        self.cuLoading = false
      }
    },
    fetchData: function () {
      let self = this
      self.loading = true
      axios.get("/home")
        .then(function (response) {
          var data = _.get(response, 'data', false)
          if (data) {
            self.myUpdate(data)
          }
          self.loading = false
        })
        .catch(function () {
          self.loading = false
          self.sbMessage = 'Server Error.  Try refreshing page.'
          self.sbOpen = true
        })
    },
  },
  computed: {
    games: function () {
      return [
        {
          name: 'Le Plateau (alpha)',
          image: 'plateau-box',
          homelink: { name: 'plateau-home' },
          playlink: { name: 'plateau-games' },
          joinlink: { name: 'plateau-join' },
          createlink: { name: 'plateau-new' },
        },
        {
          name: 'After the Flood',
          image: 'atf-box',
          homelink: { name: 'sng-home' },
          playlink: { name: 'sng-games', params: { type: 'atf', status: 'running' } },
          joinlink: { name: 'sng-join-game', params: { type: 'atf', status: 'recruiting' } },
          createlink: { name: 'sng-new-game', params: { type: 'atf' } },
        },
        {
          name: 'Confucius',
          image: 'confucius-box',
          homelink: { name: 'sng-home' },
          playlink: { name: 'sng-games', params: { type: 'confucius', status: 'running' } },
          joinlink: { name: 'sng-join-game', params: { type: 'confucius', status: 'recruiting' } },
          createlink: { name: 'sng-new-game', params: { type: 'confucius' } },
        },
        {
          name: 'Guild of Thieves',
          image: 'got-box',
          homelink: { name: 'got-home' },
          playlink: { name: 'sng-games', params: { type: 'got', status: 'running' } },
          joinlink: { name: 'sng-join-game', params: { type: 'got', status: 'recruiting' } },
          createlink: { name: 'got-new-game' },
        },
        {
          name: 'Indonesia',
          image: 'indonesia-box',
          homelink: { name: 'sng-home' },
          playlink: { name: 'sng-games', params: { type: 'indonesia', status: 'running' } },
          joinlink: { name: 'sng-join-game', params: { type: 'indonesia', status: 'recruiting' } },
          createlink: { name: 'sng-new-game', params: { type: 'indonesia' } },
        },
        {
          name: 'Tammany Hall (Beta)',
          image: 'tammany-box',
          homelink: { name: 'tammany-home' },
          playlink: { name: 'tammany-games' },
          joinlink: { name: 'tammany-join' },
          createlink: { name: 'tammany-new' },
        },
      ]
    }
  }
}
</script>
