import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/home/Home'
import New from '@/components/invitation/New'
import Invitations from '@/components/invitation/Index'
import Games from '@/components/game/Index'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/invitation/new/:type',
      name: 'new',
      component: New
    },
    {
      path: '/invitations/:type',
      name: 'invitations',
      component: Invitations
    },
    {
      path: '/plateau-home',
      name: 'plateau-home',
      beforeEnter() {
        let plateauHome = process.env.VUE_APP_PLATEAU_HOME
        window.location.href = plateauHome
      }
    },
    {
      path: '/plateau-games',
      name: 'plateau-games',
      beforeEnter() {
        let plateauHome = process.env.VUE_APP_PLATEAU_HOME
        window.location.href = `${plateauHome}games/running`
      }
    },
    {
      path: '/plateau-join',
      name: 'plateau-join',
      beforeEnter() {
        let plateauHome = process.env.VUE_APP_PLATEAU_HOME
        window.location.href = `${plateauHome}join`
      }
    },
    {
      path: '/plateau-new',
      name: 'plateau-new',
      beforeEnter() {
        let plateauHome = process.env.VUE_APP_PLATEAU_HOME
        window.location.href = `${plateauHome}new`
      }
    },
    {
      path: '/tammany-home',
      name: 'tammany-home',
      beforeEnter() {
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        window.location.href = tammanyHome
      }
    },
    {
      path: '/tammany-games',
      name: 'tammany-games',
      beforeEnter() {
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        window.location.href = `${tammanyHome}games/running`
      }
    },
    {
      path: '/tammany-join',
      name: 'tammany-join',
      beforeEnter() {
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        window.location.href = `${tammanyHome}join`
      }
    },
    {
      path: '/tammany-new',
      name: 'tammany-new',
      beforeEnter() {
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        window.location.href = `${tammanyHome}new`
      }
    },
    {
      path: '/got-home',
      name: 'got-home',
      beforeEnter() {
        let gotHome = process.env.VUE_APP_GOT_HOME
        window.location.href = gotHome
      }
    },
    {
      path: '/tammany-home',
      name: 'tammany-home',
      beforeEnter() {
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        window.location.href = tammanyHome
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/games/:status/:type',
      name: 'games',
      component: Games
    },
    {
      path: '/ugames/:uid/:status/:type',
      name: 'ugames',
      component: Games
    },
    {
      path: '/game/:type/:id',
      name: 'game',
      beforeEnter(to) {
        let gotHome = process.env.VUE_APP_GOT_HOME
        let plateauHome = process.env.VUE_APP_PLATEAU_HOME
        let tammanyHome = process.env.VUE_APP_TAMMANY_HOME
        let sngHome = process.env.VUE_APP_SNG_HOME
        switch (to.params.type) {
          case 'got':
            window.location.href = `${gotHome}#/game/${to.params.id}`
            break;
          case 'plateau':
            window.location.href = `${plateauHome}game/${to.params.id}`
            break;
          case 'tammany':
            window.location.href = `${tammanyHome}game/${to.params.id}`
            break;
          default:
            window.location.href = `${sngHome}${to.params.type}/game/show/${to.params.id}`
        }
      }
    },
    {
      path: '/user/show/:uid',
      name: 'user-show',
      beforeEnter(to) {
        const userv = process.env.VUE_APP_USER_FRONTEND
        window.location.href = `${userv}user/${to.params.uid}`
      }
    },
  ]
})
