<template>
  <v-card height="31em">
    <v-img
      height='217px'
      position='top center'
      :src="boxPath('confucius')"
      />
      <v-card-text>
        <v-row dense>
          <v-col cols='5'>Designer</v-col>
          <v-col>Alan Paull</v-col>
        </v-row>
        <v-row dense>
          <v-col cols='5'>Artists</v-col> 
          <v-col>Anthony Boydell</v-col>
          <v-col>Charlie Paull</v-col> 
        </v-row>
        <v-row dense> 
          <v-col cols='5'>Publisher</v-col> 
          <v-col>Surprised Stare Games Ltd</v-col>
        </v-row>
        <v-row dense>
          <v-col cols='5'>Year Published</v-col>
          <v-col>2008</v-col>
        </v-row>
        <v-row dense> 
          <v-col cols='5'>On-Line Developer</v-col> 
          <v-col>Jeff Huter</v-col> 
        </v-row> 
        <v-row dense> 
          <v-col cols='5'>Permission Provided By</v-col> 
          <v-col>Alan Paull</v-col> 
        </v-row> 
        <v-row dense> 
          <v-col cols='5'>Rules (pdf)</v-col> 
          <v-col>Confucius <a href="/rules/confucius-rules.pdf">(EN)</a></v-col> 
        </v-row> 
      </v-card-text>
  </v-card>
</template>

<script>

import BoxPath from '@/components/mixins/BoxPath'

export default {
  name: 'newInvitation',
  mixins: [ BoxPath ],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2, h3 {
  font-weight: normal;
}
</style>
