<template>
  <v-card height="31em">
    <v-card-title primary-title>
      <h3>New Invitation</h3>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          label="Title"
          v-model="invitation.title"
          >
        </v-text-field>
          <v-text-field
            label='Password'
            v-model='invitation.password'
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            placeholder="Enter Password for Private Game"
            clearable
            @click:append="show = !show"
            >
          </v-text-field>
            <v-btn color='green' dark @click="$emit('submit', invitation)">Submit</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import CurrentUser from '@/components/lib/mixins/CurrentUser'
import BoxPath from '@/components/mixins/BoxPath'

export default {
  name: 'newInvitation',
  mixins: [ CurrentUser, BoxPath ],
  props: [ 'invitation' ],
  data () {
    return {
      show: false,
      path: '/invitation/new',
      nav: false,
      npItems: [ { text: '3', value: 3 }, ],
      optItems: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2, h3 {
  font-weight: normal;
}
</style>
