<template>
  <tr>
    <td class='text-left'>
      <slot></slot>
    </td>
    <td class='text-left'>
      <sn-user-btn :user="user" x-small>{{user.name}}</sn-user-btn> 
    </td>
    <td class='text-center'>
      {{glo}}
    </td>
    <td class='text-center'>
      {{projected}}
    </td>
    <td class='text-center'>
      {{played}}
    </td>
    <td class='text-center'>
      {{won}}
    </td>
    <td class='text-center'>
      {{wp}}%
    </td>
  </tr>
</template>

<script>
import UserButton from '@/components/lib/user/Button'

const _ = require('lodash')

export default {
  name: 'sn-expanded-table-row',
  props: [ 'details', 'user' ],
  components: {
    'sn-user-btn': UserButton
  },
  computed: {
    glo: function () {
        return _.get(this.details, 'glo', 0)
    },
    projected: function () {
        return _.get(this.details, 'projected', 0)
    },
    played: function () {
        return _.get(this.details, 'played', 0)
    },
    won: function () {
        return _.get(this.details, 'won', 0)
    },
    wp: function () {
        return _.get(this.details, 'wp', 0)
    },
  },
}
</script>
